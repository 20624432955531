import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { auth } from "./databaseConfig";
import { Info } from "./Info";
import NavBar from "./Navbar";


export default function HomeScreen(){
    const [loaded, setLoaded] = useState(null);
    useEffect(() => {
        setTimeout(() => setLoaded(true), 2000)
    }, [])
    const [linkPresent, setLinkPresent] = useState(null);
    const [invalidLink, setInvalidLink] = useState(null);
    const [url, setUrl] = useState(null);
    const [signedIn, setSignedIn] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (auth?.currentUser?.displayName !== undefined){
            setSignedIn(true);
        }
    })
    

    function jumpToSummary() {
        navigate("/youtube-video-summary", { state: { yturl: url } } )
    }
    function validVideoId(id) {

        try {
            
            let f_id = (id.split("="))
            let mobile_f_id = (id.split(".be/"))
            let normal = false
            let mobile = false
            

            if (f_id[0] === "https://www.youtube.com/watch?v"){
                normal = true
            } 
            if (mobile_f_id[0] === "https://youtu"){
                mobile = true
            }

            if (!normal && !mobile){
                setLinkPresent(null);
                throw Error("invalid")
            }

            if (normal){
                if (f_id[1].includes("&")){
                    f_id = f_id[1].split("&")[0]
                } else {
                    f_id = f_id[1]
                }
            } else{
                f_id = mobile_f_id[1]
                if (f_id.includes("?")){
                    f_id = f_id.split("?")[0]
                }
            }
            var img = new Image();
            img.src = "http://img.youtube.com/vi/" + f_id + "/mqdefault.jpg";
            img.onload = function () {
                checkThumbnail(this.width, id);
            }
        } catch{
            if (id === ""){
                setInvalidLink(null);
            } else {
                setInvalidLink(true);
            }

        }

		
	}

	function checkThumbnail(width, url) {
		//HACK a mq thumbnail has width of 320.
		//if the video does not exist(therefore thumbnail don't exist), a default thumbnail of 120 width is returned.
		if (width === 120) {
			setLinkPresent(null);
            setInvalidLink(true)
		} else {
            setInvalidLink(null);
            setLinkPresent(true);
            setUrl(url);
        }
	}


    return (
        <div>
            
                <NavBar signedIn={signedIn}></NavBar>
          
            

            <div className="type grid">
                <div>
                    <h1 className="pretty locSet large text-gradient">Video summarization  </h1>

                    <h1 className="indent-left significant">Taken to another <p className="inline underline">Level</p></h1>

                        {loaded &&
                        <div className="fade">
                            <div className="pretty medium indent-left type2">
                                Summarizing videos, with videos
                            </div>
                            <div className="indent-left pad-top indent-top">
                                <h1 className="pretty inline2 medium pad-left pad"> See it in action </h1>
                                <input style={{paddingLeft:"2.917vw", width:"50%"} }onChange={(e) => validVideoId(e.target.value)} className="rounded input-pretty" placeholder="      🔗     Drop a YouTube link here"></input>
                                <br></br>
                                {linkPresent && 
                                <button onClick={jumpToSummary} className="rounded pretty button-pretty titleButton" type="submit">Get Summary</button>}
                                {invalidLink && 
                                <Info></Info>
                                }
                            </div>
                        </div>
                         }
                
                </div>
                {loaded && <div className="fade" style={{marginTop:"20%", marginLeft:"5%"}}>
                    <img className="inline margin-left icon" src="./summarizericon.jpeg" alt="icon" width="200"height="200"/>
                </div>}
            </div>


        </div>
    )
}
