
import { useState, useEffect } from "react";
import LogInBtn from "./LogIn.js";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
export default function Authentication() {
   const [url, setUrl] = useState("")
   const location = useLocation()
   useEffect(() => {
    const urlData = location.state
    try {
        setUrl(urlData["yturl"])
    } catch {
        
    }

}, [])

    return (
        <div className="">
             <div className="pretty">
                <h1 className="center pad-down med-large"> Sign up, it's free. </h1>
                
                <div className="center">
                <LogInBtn scale={200} mt={50} navigate="/youtube-video-summary"url={url}></LogInBtn>
                </div>

                <div className="center" style={{marginTop:"4%"}}>
                    <Link to="/"><img src="./summarizericon.jpeg" className="icon" alt="icon" width="400"height="400"/> </Link>  
                </div>
            
            </div>
        </div>
    )
}
