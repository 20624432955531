import { auth, googleProvider } from "./databaseConfig.js"
import { signInWithPopup } from "firebase/auth"
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function LogInBtn(props){
    const navigate = useNavigate();
    const location = useLocation()
    const [url, setUrl] = useState("")

    useEffect(() => {
        const urlData = location.state
        try {
            setUrl(urlData["yturl"])
        } catch {
            
        }
    
    }, [])
    const signInWithGoogle = async () => {
        try{
        await signInWithPopup(auth, googleProvider);
        navigate(props.navigate, { state: { yturl: url } })
        } catch (err) {
            console.error(err);
        }
    }

    return (
    <div className="small">
        <button onClick={signInWithGoogle} style={{scale:props.scale ? props.scale.toString()+"%" : "100%", marginTop: props.mt ? props.mt.toString()+"%" : ""}} className="button-pretty rounded small"> Sign in with Google</button>
    </div>
    )
}
