import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Videos from "./Video.js"
import HomeScreen from './TitleScreen';
import Authentication from './Auth';
function Home(){
  return (
  <div className="App">
    <HomeScreen></HomeScreen>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>

    {/*
    <Link to="/"><img src="./summarizericon.jpeg" alt="icon" width="150"height="150"/> </Link>  
  <Videos></Videos>*/}
  
  </div>
  )
}
function Video(){
  return (
    <div>

    <Videos></Videos>
    </div>
  )
}
function SignIn(){
  return (
  <Authentication></Authentication>
  )
}
function App() {
  return (
    <Router>
            <div>
                <Routes>
                  <Route path = "/" exact Component={Home}></Route>
                  <Route path = "/youtube-video-summary" exact Component={Video}></Route>
                  <Route path = "/sign-in" exact Component={SignIn}></Route>
                </Routes>
            </div>
        </Router>
  );
}

export default App;
