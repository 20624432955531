import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { auth } from "./databaseConfig";
import { Info } from "./Info";


export default function Videos(){
    
    
    const [inputValue, setInputValue] = useState('');
    const [videoSrc, setVideoSrc] = useState(null);
    const [progressValue, setProgress] = useState(null);
    const [taskStart, setTaskStart] = useState(false);
    const [id, setID] = useState("")
    const [progressPercentage, setProgressPercentage] = useState(null);
    const location = useLocation()
    const [linkPresent, setLinkPresent] = useState(null);
    const [invalidLink, setInvalidLink] = useState(null);
    const [summaryLength, setSummaryLength] = useState(1);
    const [btn_x, setBtnSize] = useState(7);
    const scale = 1.5
    const navigate = useNavigate()
    const baseURL = "http://summaryze-alb-1685808113.us-east-2.elb.amazonaws.com:8080"
/*
    useEffect(() => {
        
        console.log(auth?.currentUser?.displayName)
        if (auth?.currentUser?.displayName === undefined){
             navigate("/sign-in")
        }
    })
*/
    // if came from home page, input url data preemptively 
    useEffect(() => {
        const urlData = location.state
        try {
            const url = urlData["yturl"]
            console.log(url)
            setInputValue(url);
            validVideoId(url)
        } catch {
            
        }

    }, [])
    
    // poll every second for task progress
    useEffect(() => {

        if (taskStart){
            const interval = setInterval(() => {
                fetchProgress(id)

            }, 1000)
            
            return () => clearInterval(interval);
        }

   },[taskStart])

   //  
   
  useEffect(() => {
    
    const handleResize = () => {
        setBtnSize(7 + (1440 - window.innerWidth)/100)
     
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });




    function validVideoId(id) {
        setInputValue(id);
        try {
            
            let f_id = (id.split("="))
            let mobile_f_id = (id.split(".be/"))
            console.log(mobile_f_id)
            let normal = false
            let mobile = false
            

            if (f_id[0] === "https://www.youtube.com/watch?v"){
                normal = true
            } 
            if (mobile_f_id[0] === "https://youtu"){
                mobile = true
            }

            if (!normal && !mobile){
                setLinkPresent(null);
                throw Error("invalid")
            }

            if (normal){
                if (f_id[1].includes("&")){
                    f_id = f_id[1].split("&")[0]
                } else {
                    f_id = f_id[1]
                }
            } else{
                f_id = mobile_f_id[1]
                if (f_id.includes("?")){
                    f_id = f_id.split("?")[0]
                    console.log(f_id)
                }
            }
            var img = new Image();
            img.src = "http://img.youtube.com/vi/" + f_id + "/mqdefault.jpg";
            img.onload = function () {
                checkThumbnail(this.width, id);
            }
        } catch {
            if (id == ""){
                setInvalidLink(null);
            } else {
                setInvalidLink(true);
            }

        }

		
	}

	function checkThumbnail(width, url) {
		//if the video does not exist(therefore thumbnail don't exist), a default thumbnail of 120 width is returned.
		if (width === 120) {
			setLinkPresent(null);
            setInvalidLink(true)
		} else {
            setInvalidLink(null);
            setLinkPresent(true);

        }
	}


    function filterMobileLink(link){
        let id = (link.split(".be/"))[1]

        if (id.includes("?")){
            id = id.split("?")[0]
        }
	console.log(id)
        return id


    }

    const FetchSummary = async () => {
        console.log(auth?.currentUser?.displayName)
        if (auth?.currentUser?.displayName === undefined){
            navigate("/sign-in",  { state: { yturl: inputValue } } )
            return 
            
        }

            // pause until response recieved
            // ec2-3-17-141-34.us-east-2.compute.amazonaws.com
            // 

        try{
           const response = await fetch(baseURL + "/start", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([inputValue.includes("youtube") ? inputValue : "https://www.youtube.com/watch?v=" + filterMobileLink(inputValue), summaryLength]) 
            });
            /*
            const data = await response.blob();
            const objectURL = URL.createObjectURL(data);
            setVideoSrc(objectURL);
            */
           console.log("hi");
           const id = await response.json();
           setID(id[0])
           setTaskStart(true);



        } catch (error) {
            console.log("THE ERROR IS ", error)

        }
     
    }

    
    const fetchProgress = async (id) => {

        // pause until response recieved
        // ec2-3-17-141-34.us-east-2.compute.amazonaws.com
        // 

        try{
        const response = await fetch(baseURL + "/progress", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 1: id })  
            });

        const progress = await response.json();
        console.log(progress["progress"])
        if (progress["progress"] == "Finished"){
            setTaskStart(false);
            setVideoSrc(null);
            fetchVideo(id);

        }
        if (progress["progress"] == "Transcript Unavailable"){
            setTaskStart(false)
            setVideoSrc(null)
        }
        setProgress(progress["progress"])
        setProgressPercentage(progress["completed"])
       
        


        } catch (error) {
            console.log("THE ERROR IS ", error)

        }
    
        }

        const fetchVideo = async (id) => {

            // pause until response recieved
            // ec2-3-17-141-34.us-east-2.compute.amazonaws.com
            // 

        try{
           const response = await fetch(baseURL + "/final", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)  
            });

            const data = await response.blob();
            const objectURL = URL.createObjectURL(data);
            setVideoSrc(objectURL);


        } catch (error) {
            console.log("THE ERROR IS ", error)

        }
     
    }

    function setCustomSummaryLen(len){

        if (Math.abs(len) > 10){
            setSummaryLength(10)
        } else{
            setSummaryLength(Math.abs(len))
        }
    }


    return (
        <div>
            <div className="center pad-up">
                <Link to="/"><img src="./summarizericon.jpeg" alt="icon" className="icon"/> </Link>  
            </div>
           {/* <BIPair onClick={FetchSummary} name="FetchSummary"></BIPair>*/}

           <div className="center med-small" style={{padding:"0.5%", scale:"90%"}}>
                <input value={inputValue} className="input-pretty videoInput" type="text" placeholder="             🔗     Drop a YouTube link here"
                onChange={(e) => validVideoId(e.target.value)} />
                <button disabled={!linkPresent} className="button-pretty pretty rounded videoButton" onClick={FetchSummary}>Submit</button>

                {invalidLink && <Info inline={true}></Info>}

            </div> 

            


            {(!progressPercentage || progressValue === "Finished" || progressValue === "Transcript Unavailable") && <div>

                <div className="center pretty">
                <button onClick={() => setSummaryLength(1)} className="circular" style={{width: summaryLength===1 ? btn_x.toString()+"vw" : (btn_x/scale).toString()+"vw", height: summaryLength===1 ? btn_x.toString()+"vw" : (btn_x/scale).toString()+"vw"}}>1 min</button>
                <button disabled className="stretched"></button>
                <button onClick={() => setSummaryLength(2)} className="circular" style={{width: summaryLength===2 ? btn_x.toString()+"vw" : (btn_x/scale).toString()+"vw", height: summaryLength===2 ? btn_x.toString()+"vw" : (btn_x/scale).toString()+"vw"}}>2 min</button>
                <button disabled className="stretched"></button>
                <button onClick={() => setSummaryLength(3)}  className="circular" style={{width: summaryLength===3 ? btn_x.toString()+"vw" : (btn_x/scale).toString()+"vw", height: summaryLength===3 ? btn_x.toString()+"vw" : (btn_x/scale).toString()+"vw"}}>3 min</button>
                <button disabled className="stretched"></button>
                <button onClick={() => setSummaryLength(4)} className="circular" style={{width: summaryLength===4 ? btn_x.toString()+"vw" : (btn_x/scale).toString()+"vw", height: summaryLength===4 ? btn_x.toString()+"vw" : (btn_x/scale).toString()+"vw"}} >4 min</button>
                <button disabled className="stretched"></button>
                <input onChange={(e) => setCustomSummaryLen((e.target.value === "" ? 1: parseFloat(e.target.value)))} className="circular custom" placeholder="Other" type="number" style={{width: (summaryLength != 1 && summaryLength != 2 && summaryLength != 3 && summaryLength != 4) ?  btn_x.toString()+"vw": (btn_x/scale).toString()+"vw", height:(summaryLength != 1 && summaryLength != 2 && summaryLength != 3 && summaryLength != 4) ?  btn_x.toString()+"vw": (btn_x/scale).toString()+"vw"}}></input>                
                </div>
                <br></br>
                <div className="pretty center">
                    <h2>Summary Length:&nbsp;</h2> 
                    <h2 className="text-gradient">{summaryLength}</h2>
                </div>

            </div>}
            {(progressValue === "Transcript Unavailable") && 
                <div className="pretty center medium warn">
                    Transcript Unavailable
                </div>}


            {progressPercentage && 
            <div>
              <div className="center"> 
              <h1 className="prg">{progressValue} &nbsp; <p className="inline text-gradient">{progressPercentage}%</p></h1> 
                </div>
                <div className="pretty pad large">
                    <ProgressBar style={{height:"2rem"}} variant="info" now={progressPercentage} label={`${progressPercentage}%`} />
                </div>
            </div>
            }

            <br></br>
            
            {(progressValue === "Finished" && !videoSrc) &&
            <div className="pretty center medium">
              Fetching final video, give us a few moments.  
            </div>}
           {videoSrc &&
            <div className="center">
             <video controls width="600">
                    <source src={videoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </div>}

        </div>
    )
}


