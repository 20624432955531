import React from 'react'

export const Button = (props) => {
  // props:
  // Button size: x, y 
  // Button actions: onclick or link 
  // Button content: text (can also specify fontSize) svg, or imagePath (can also specify imagew and imageh)

  return (

    <button className="default-btn-style" onMouseOver={props.onhover ? props.onhover : () => {}}
    style={{paddingLeft:props.x+"%", paddingRight:props.x+"%", paddingTop: props.y+"%", paddingBottom:props.y+"%", fontSize:props.fontSize+"%"}} 

    onClick={props.onclick ?  props.onclick: () => window.open(props.link, "_blank")}>


        {props.text}

        {props.svg}

        {props.imagePath && <img style={{borderRadius:"50%", background:"black"}} className='' src={props.imagePath} width={props.imagew} height={props.imageh}></img>}



    </button>

  )
}
