import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyC6tvvquAknj0lu5bD6OqUwyYFMIsXSeX8",
    authDomain: "video-summarizer-b0192.firebaseapp.com",
    projectId: "video-summarizer-b0192",
    storageBucket: "video-summarizer-b0192.appspot.com",
    messagingSenderId: "1043379637005",
    appId: "1:1043379637005:web:2a0d010847ea7ebf213e44",
    measurementId: "G-9EEBVQH7VM"
  };




const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(firebaseApp);
