
import LogInBtn from "./LogIn"
import LogOutBtn from "./LogOut"
import { auth } from "./databaseConfig";

export default function NavBar(props){
    return (
        <nav className="pretty pad-down underline-curve">
                    <ul>
                        <li className="lrg"><p className="inline pad-left">Summar</p><img className src="./summarizericon.jpeg" alt="icon" width="40"height="40"/><p className="inline pad-right">ze</p></li>
                        <li><a href="/youtube-video-summary" className="med-small inline">try it now</a></li>
                       <li style = {{fontSize:"1.319vw"}}><a href="/"></a></li>
                        {!props.signedIn && <li style={{float:"right"}}>
                    
                            <LogInBtn navigate="/"></LogInBtn>
                            
                            </li>
                            }
                        {props.signedIn && 
                            <li style={{float:"right"}}>
                                <h2 className="small">{auth?.currentUser?.displayName} </h2>
                                <LogOutBtn></LogOutBtn>
                            </li>
                        

                            }

                    </ul>
                
        </nav>
    )
}
