import { auth } from "./databaseConfig.js"
import {  signOut } from "firebase/auth"

export default function LogOutBtn(){
    const logOut = async () => {
        try{
        await signOut(auth);
        window.location.reload(false);

        } catch (err) {
            console.error(err);
        }
    }

    return (
        <button className="button-pretty rounded small" onClick={logOut}>Log Out</button>

    )
}
