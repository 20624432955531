import React, { useState } from 'react'
import { Button } from './Button'
export const Info = (props) => {
  const [infoState, setInfoState] = useState(0)
  function revealInfo(){
    if (infoState === 0){
      if (props.inline){
        document.getElementById("info").style.display = "inline-block"
      } else {
        document.getElementById("info").style.display = ""
      }
        document.getElementById("info").style.animation = "fadein 0.5s linear forwards"
        setInfoState(1)
    } 
    if (infoState === 1){
      document.getElementById("info").style.animation = "fadeout 1s linear forwards"
      const interval = setInterval(() => {
        document.getElementById("info").style.display = "none"
        clearInterval(interval)
      }, 900);

      setInfoState(0)
    }


}
  return (
    <div className=''>
       {!props.inline && <div className=''>
          <h1 className="pad-down pretty med-small titleInvalid inline">Invalid Link &nbsp;</h1>
          <Button onclick={revealInfo} x="1" y="1" imagePath="info.png" imagew="23px" imageh="23px" id="infoBtn"></Button>
        </div>}
        <div className={props.inline ? "" : "margin-left"}>

            <div id="info" className={props.inline ? "info rounded padL" : "info rounded pad"} style={{opacity:0, display: "none"}}>
                
            <h2 className="pretty med-info pad center info m-0 pad-up rounded"><span className="text-gradient-red">Youtube&nbsp; </span> links formatted like:</h2>

                <h2 className="pretty info-small center m-0 info">https://www.youtube.com/watch?=</h2>
                <h2 className="pretty info-small center m-0 info">https://youtu.be/</h2>
                <h2 className='pretty info-small center m-0 info'>_______________________________</h2>

                <h2 className='pretty info-small center info m-0 pad-down'><div className='text-gradient'>Uploading</div>&nbsp;& <div className='text-gradient'>&nbsp;Recording</div></h2>
                <h2 className="pretty info-small center info m-0 pad-up" >will be supported soon</h2>
            

            </div>
        </div>
        {props.inline && <div className='center'>

          <h1 className="pad-down pretty med-small inline margin-left-s">Invalid Link &nbsp;</h1>

          <div className='center'>
          <Button onclick={revealInfo} x="1" y="1" imagePath="info.png" imagew="23px" imageh="23px" id="infoBtn"></Button>
          </div>
        </div>}
        

    </div>
  )
}
